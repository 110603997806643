import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import Spinner from "../../components/common/Spinner"
import CenteredLayout from "../../components/layout/CenteredLayout"
import HybridStorage from "../../config/hybridStorage";

const Clear = () => {
  if (typeof window !== "undefined") {
    new HybridStorage({
      domain: process.env.NEPAL_DOMAIN,
      expires: 7,
      path: "/",
      sameSite: "lax",
      secure: process.env.NODE_ENV === "production",
    }).clear();
  }

  if (typeof window !== "undefined" && window.parent) {
    window.parent.postMessage({ topic: 'session-cleared' }, '*');
  }

  return <CenteredLayout>
    <h6 className="text-uppercase text-muted mb-4">
      <FormattedMessage
        id="pages.storage.clear"
        defaultMessage="Storage deletion in progress..."
        description=""/>
    </h6>
    <Spinner/>
  </CenteredLayout>
}
export default Clear
